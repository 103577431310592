import React from "react"
import { graphql } from "gatsby"
import { normalizeHomePage } from "../helpers"
import { allRoutes } from "../helpers/routes"
import Layout from "../components/layout"
import Video from "../components/video"
import LinkBtn from "../components/linkBtn"
import Img from "gatsby-image"

const HomePage = ({ data }) => {
  const { intro, longerIntro, video } = normalizeHomePage(data.allContentfulHomePage.edges[0].node)
  return (
    <Layout mainClass="home">
      <div className="hero">
        <div className="hero__image">
          <Img fluid={data.exhibitHall.childImageSharp.fluid} alt="Exhibit hall" />
        </div>
        <header className="hero__message">
          <h1>{intro}</h1>
        </header>
      </div>
      <div className="content-area">
        <p className="content-area__intro">{longerIntro}</p>
        {video && <Video title="title" videoUrl={video} />}
        <div className="cta-block-bottom">
          <LinkBtn className="btn" to={allRoutes.directory}>
            Enter the Virtual Exhibit Hall
          </LinkBtn>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {
    allContentfulHomePage {
      edges {
        node {
          shortIntroduction
          slightlyLongerIntroduction
          introductoryVideoFor2023
        }
      }
    }
    exhibitHall: file(relativePath: { eq: "exhibit-hall-7.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default HomePage